import {graphql} from 'gatsby';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';
import Layout from '../Layout/index.js';
import SearchCard from '../BlogLandingPage/SearchCard';
import {Col, Container, Hidden, Row} from 'react-awesome-styled-grid';
import {Separator1, Separator2} from '../BlogLandingPage/LocalStyles';
import SubsCard from '../BlogLandingPage/SubsCard';
import {
  BackToCategories,
  ContactSection,
  CategoryContainer,
  LinkContainer,
  MainSection,
} from './LocalStyles';
import {
  Heading03,
  Heading05,
  Paragraph02,
  Subheading,
} from '../core/Typography/Typography.styles';
import {Link} from 'gatsby-plugin-react-i18next';
import {Helmet} from 'react-helmet';
import SEO from '../seo';

const KnowledgeBaseCategory = ({data, pageContext}) => {
  const itemAmount = 10;
  const [displayedItems, setDisplayedItems] = useState(itemAmount);

  const knowledgebase = useMemo(() => {
    const url = 'https://www.dacast.com';
    let questions;
    let category;
    let canonical;
    if (data.knowledgebase.edges.length > 0) {
      questions = data.knowledgebase.edges.map(item => item.node);
    }
    if (pageContext.category) {
      category = pageContext.category;
      canonical = url.concat(
        pageContext.language === 'en'
          ? pageContext.uri
          : `/${pageContext.language}${pageContext.uri}`
      );
    }
    const faqObject = questions.filter(question =>
      question.articleCategories.nodes.find(item => item.name === category)
    );
    return {category, questions: faqObject, canonical};
  }, [data]);

  const searchCategories = useMemo(() => {
    if (data.knowledgebaseCategories.edges.length > 0) {
      let allQuestions;
      let returnQuestions;
      let validCategories;
      returnQuestions = [];
      validCategories = [
        'mobile-live-streaming',
        'video-on-demand-vod',
        'video-analytics',
        'manage-account',
        'player',
        'videohosting',
        'dacast-ovp',
        'monetize-video',
        'encoders',
        'livestreaming',
      ];
      allQuestions = data.knowledgebaseCategories.edges.map(item => item.node);

      allQuestions.forEach(function (q) {
        if (validCategories.includes(q.slug)) {
          if (q.slug == 'dacast-ovp') {
            q.name = 'Video Platform';
          }
          if (q.slug == 'video-on-demand-vod') {
            q.name = 'VOD';
          }
          if (q.slug == 'mobile-live-streaming') {
            q.name = 'Mobile';
          }
          returnQuestions.push(q);
        }
      });
      return returnQuestions;
    }
    return [];
  }, [data]);

  const locales = useMemo(() => {
    if (data.locales.edges.length > 0) {
      return data.locales.edges.map(item => item.node);
    }
    return [];
  }, [data]);

  const seo = useMemo(() => {
    return {
      canonical: `https://www.dacast.com/${
        pageContext.language === 'en' ? '' : `${pageContext.language}/`
      }support/knowledgebase/`,
      title: `Best Knowledge Base for Live Streaming and Video Hosting | ${pageContext.category}`,
      metaDesc:
        'Looking for the best tips on how to live stream and host your online video ? Check out Dacast video and written tutorials and enjoy 30 days of free streaming!',
      opengraphType: 'article',
      opengraphSiteName: 'Dacast',
      opengraphPublisher: 'https://www.facebook.com/DacastStreaming/',
      opengraphPublishedTime: '',
      opengraphModifiedTime: '2020-06-15T23:08:41+00:00',
      schema: {
        articleType: [],
        pageType: ['WebPage'],
        raw:
          '{"@context":"https://schema.org","@graph":[{"@type":"WebSite","@id":"https://www.dacast.com/#website","url":"https://www.dacast.com/","name":"Dacast","description":"Video Hosting &amp; Live Streaming Solutions","potentialAction":[{"@type":"SearchAction","target":{"@type":"EntryPoint","urlTemplate":"https://www.dacast.com/?s={search_term_string}"},"query-input":"required name=search_term_string"}],"inLanguage":"en-US"},{"@type":"WebPage","@id":"https://www.dacast.com/support/knowledgebase/#webpage","url":"https://www.dacast.com/support/knowledgebase/","name":"Best Knowledge Base for Live Streaming and Video Hosting","isPartOf":{"@id":"https://www.dacast.com/#website"},"datePublished":"2019-01-10T22:06:28+00:00","dateModified":"2020-06-15T23:08:41+00:00","description":"Looking for the best tips on how to live stream and host your online video ? Check out Dacast video and written tutorials and enjoy 30 days of free streaming!","breadcrumb":{"@id":"https://www.dacast.com/support/knowledgebase/#breadcrumb"},"inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["https://www.dacast.com/support/knowledgebase/"]}]},{"@type":"BreadcrumbList","@id":"https://www.dacast.com/support/knowledgebase/#breadcrumb","itemListElement":[{"@type":"ListItem","position":1,"name":"Home","item":"https://www.dacast.com/"},{"@type":"ListItem","position":2,"name":"24/7 Support","item":"https://www.dacast.com/support/"},{"@type":"ListItem","position":3,"name":"Knowledge Base"}]}]}',
      },
    };
  }, [pageContext]);

  const translated = useMemo(() => {
    if (data.knowledgeBasePageData.edges.length > 0) {
      return data.knowledgeBasePageData.edges.map(item => item.node).translated;
    }
    return [];
  }, [data]);

  const handleShowMore = () => {
    if (displayedItems > itemAmount) {
      setDisplayedItems(itemAmount);
    } else {
      setDisplayedItems(knowledgebase.questions.length);
    }
  };

  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href={knowledgebase.canonical} />
      </Helmet>
      <SEO
        seo={seo}
        lang={pageContext.language}
        locales={locales}
        translated={translated}
      />
      <MainSection>
        <Container>
          <Row>
            <Col>
              <SearchCard
                categories={searchCategories}
                type="faq"
                backdrop="https://www.dacast.com/wp-content/uploads/2021/12/KB.png"
              />
            </Col>
          </Row>
          <Separator1 />
          <Separator2 />
          <Link to="/support/knowledgebase/">
            <BackToCategories>Back to Categories</BackToCategories>
          </Link>
          <LinkContainer>
            <Heading05 fontWeight="medium" small>
              {knowledgebase.category}
            </Heading05>
            <Heading03 fontWeight="medium">{knowledgebase.category}</Heading03>
          </LinkContainer>
          {knowledgebase.questions
            .slice(0, displayedItems)
            .map(({title, uri, id}) => (
              <Link to={uri} key={id}>
                <CategoryContainer>
                  <Hidden lg xl>
                    <Paragraph02 fontWeight="regular">{title}</Paragraph02>
                  </Hidden>
                  <Hidden xs sm md>
                    <Subheading fontWeight="regular">{title}</Subheading>
                  </Hidden>
                </CategoryContainer>
              </Link>
            ))}
          {knowledgebase.questions.length > 10 && (
            <LinkContainer onClick={handleShowMore}>
              <Hidden lg xl>
                <Paragraph02 fontWeight="regular" palette="primary" scale="500">
                  Show {displayedItems === itemAmount ? 'All' : 'Less'}
                </Paragraph02>
              </Hidden>
              <Hidden xs sm md>
                <Subheading fontWeight="regular" palette="primary" scale="500">
                  Show {displayedItems === itemAmount ? 'All' : 'Less'}
                </Subheading>
              </Hidden>
            </LinkContainer>
          )}
        </Container>
      </MainSection>
      <ContactSection>
        <Container>
          <Row>
            <Col xs={4} sm={4} lg={8}>
              <SubsCard
                type="contact"
                backdrop="https://www.dacast.com/wp-content/uploads/2021/12/gradienta-m_7p45JfXQo-unsplash-min-scaled.jpg"
              />
            </Col>
            <Col xs={4} sm={4} lg={4}>
              <SubsCard
                type="chat"
                backdrop="https://www.dacast.com/wp-content/uploads/2021/12/jason-leung-mZNRsYE9Qi4-unsplash-min-scaled.jpg"
              />
            </Col>
          </Row>
        </Container>
      </ContactSection>
    </Layout>
  );
};

export const query = graphql`
  query($language: String!) {
    knowledgeBasePageData: allWpPost(filter: {id: {eq: "31202"}}) {
      edges {
        node {
          id
          content
          seo {
            canonical
            title
            metaDesc
            opengraphType
            opengraphSiteName
            opengraphPublisher
            opengraphPublishedTime
            opengraphModifiedTime
            schema {
              articleType
              pageType
              raw
            }
          }
          translated {
            seo {
              canonical
              title
              metaDesc
              opengraphType
              opengraphSiteName
              opengraphPublisher
              opengraphPublishedTime
              opengraphModifiedTime
              schema {
                articleType
                pageType
                raw
              }
            }
          }
        }
      }
    }
    knowledgebaseCategories: allWpArticleCategory {
      edges {
        node {
          name
          uri
          slug
        }
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    knowledgebase: allWpArticle {
      edges {
        node {
          id
          databaseId
          title
          slug
          guid
          uri
          slug
          link
          articleCategories {
            nodes {
              name
            }
          }
          translated {
            id
            link
            title
            seo {
              metaRobotsNofollow
              metaRobotsNoindex
              breadcrumbs {
                text
                url
              }
              canonical
              cornerstone
              focuskw
              metaDesc
              metaKeywords
              opengraphAuthor
              opengraphDescription
              opengraphModifiedTime
              opengraphPublishedTime
              opengraphPublisher
              opengraphTitle
              opengraphSiteName
              opengraphType
              opengraphUrl
              title
              twitterDescription
              twitterTitle
              schema {
                articleType
                pageType
                raw
              }
            }
            locale {
              locale
              id
            }
          }
          locale {
            locale
            id
          }
          dateGmt
          modifiedGmt
          seo {
            metaRobotsNofollow
            metaRobotsNoindex
            breadcrumbs {
              text
              url
            }
            canonical
            cornerstone
            focuskw
            metaDesc
            metaKeywords
            opengraphAuthor
            opengraphDescription
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphPublisher
            opengraphTitle
            opengraphSiteName
            opengraphType
            opengraphUrl
            title
            twitterDescription
            twitterTitle
            schema {
              articleType
              pageType
              raw
            }
          }
          author {
            node {
              url
              slug
              name
              seo {
                social {
                  facebook
                  instagram
                  linkedIn
                  twitter
                }
              }
              avatar {
                url
                foundAvatar
              }
            }
          }
          internal {
            type
          }
        }
      }
    }
  }
`;
export default KnowledgeBaseCategory;

KnowledgeBaseCategory.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
